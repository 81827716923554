import { cloneDeep } from 'lodash';
import { useState, useMemo, useEffect, useCallback } from 'react';
import {
  Backdrop,
  Button,
  Grid,
  Modal,
  Box,
  Typography,
  ListItem,
  ListItemText,
  styled,
} from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

import useDashboardFilters, {
  RiskFilterType,
} from 'src/hooks/useDashboardFilters';
import useDashboardCategories from 'src/hooks/useDashboardCategories';
import { RISK_COLOR_ASSOCIATION } from 'src/containers/dashboard/garm/common';
import useAuth from 'src/hooks/useAuth';
import { getAllReports, createReport, deleteReport } from 'src/apis/apiReport';
import {
  Dashboard,
  GarmShowsRequestBody,
  ReportGraph as ReportGraphType,
  ReportData as ReportDataTypes,
} from 'src/types';
import { ScoreFilterType, ScoreExceptionTypes } from 'src/types/filter';
import StepView from './StepView';
import ReportGraph from './ReportGraph';
import ReportTable from './ReportTable';
import { getGarmShowReports } from 'src/apis';
import {
  convertRatings,
  getValidGarmScores,
  getGarmDashboards,
} from 'src/utils';

import { Theme } from 'src/theme/types/createPalette';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    layout: {
      width: '100%',
      height: '100%',
      padding: theme.spacing(2),
    },
    container: {
      borderRadius: theme.spacing(1),
      boxShadow: '0px 0px 0px 1px #E0E0E0',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    steps: {
      display: 'flex',
      justifyContent: 'center',
      gap: '20%',
      width: '100%',
      marginTop: 30,
      flex: 1,
    },
    modalContent: {
      height: 'calc(100vh - 105px)',
      maxHeight: 'calc(100vh - 105px)',
      overflow: 'auto',
    },
    closeIcon: {
      cursor: 'pointer',
      position: 'absolute',
      top: theme.spacing(2),
      right: theme.spacing(3),
    },
  })
);

export const LinkButton = styled(Button)(() => ({
  fontSize: 14,
  fontWeight: 500,
}));

const modalStyle = {
  position: 'absolute',
  top: 0,
  bottom: 0,
  right: 0,
  width: 350,
  bgcolor: 'background.paper',
  border: '2px solid #e0e0e0',
  borderRadius: '20px 0 0 20px',
  boxShadow: 24,
  pt: 2,
  px: 2,
  pb: 3,
};

const reportGraphModalStyle = {
  position: 'absolute',
  bottom: 0,
  right: 0,
  width: 850,
  bgcolor: 'background.paper',
  borderRadius: 0,
  boxShadow: 24,
  pt: 2,
  px: 2,
  pb: 3,
};

export default function Reports() {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [selectedDashboardReport, setSelectedDashboardReport] =
    useState<any>(null);
  const [step, setStep] = useState<number>(1);
  const [reportsData, setReportsData] = useState<ReportDataTypes[]>([]);
  const [reportGraphData, setReportGraphData] =
    useState<ReportGraphType | null>(null);
  const [dashboard, setDashboard] = useState<Dashboard | null>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openReportGraphModal, setOpenReportGraphModal] =
    useState<boolean>(false);

  const { customFilters } = useDashboardFilters();
  const { riskComponents, filterMapInit } = useDashboardCategories();
  const { organization } = useAuth();

  const garmDashboards = useMemo(
    () => (organization ? getGarmDashboards(organization) : []),
    [organization]
  );

  const handleSelectDashboard = (selectedDashboard: Dashboard) => {
    setOpenModal(false);
    if (selectedDashboard) {
      setDashboard(selectedDashboard);
      const body: GarmShowsRequestBody = {};
      if (selectedDashboard.keywordsList.length) {
        body.sources = selectedDashboard.keywordsList;
      }
      const selectedPublishers =
        (selectedDashboard.garmFilters?.publishersList as string[]) || [];
      if (selectedPublishers.length) {
        body.publishers = selectedPublishers;
      }
      const selectedIABCategories =
        (selectedDashboard.garmFilters?.iabCategories as any[]) || [];
      if (selectedIABCategories.length) {
        body.iabCategories = [
          ...selectedIABCategories.map((item) => item.name.toLowerCase()),
          ...selectedIABCategories.map((item) => item.name),
        ];
      }

      const selectedLanguages =
        (selectedDashboard.garmFilters?.languages as string[]) || [];
      if (selectedLanguages.length) {
        body.languages = selectedLanguages;
      }

      body.riskLevelDelta = 'all';

      const riskFilters = cloneDeep(filterMapInit);

      Object.values(selectedDashboard.garmFilters || {}).forEach(
        (filter: any) => {
          const found = Object.entries(filterMapInit).find(
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            ([_, each]) =>
              each.dashboardFilterKey === filter?.dashboardFilterKey
          );
          if (found) {
            riskFilters[found[0]] = {
              ...riskFilters[found[0]],
              ...filter,
              enabled: true,
            };
          }
        }
      );
      const enabledFilters: RiskFilterType[] = [];

      Object.keys(riskFilters).forEach((key) => {
        if (
          riskFilters[key].enabled ||
          riskFilters[key].filterType === 'audience'
        ) {
          enabledFilters.push(riskFilters[key]);
        }
      });
      const garmExceptions: any = {};
      if (enabledFilters && enabledFilters.length) {
        enabledFilters.forEach((filter) => {
          const filterValue: number[] = filter.value as number[];
          if (filterValue && filter.jsonKeyRisk) {
            if (filter.jsonKeyRisk === 'audience') {
              const values = convertRatings(filterValue);
              if (values) {
                body.listenerCountRange = values;
              }
            } else {
              const values = getValidGarmScores(filterValue);
              if (values) {
                garmExceptions[filter.dashboardFilterKey] = { default: values };
              }
              const selectedCustomFilter = selectedDashboard.exceptionFilterId
                ? customFilters.filter(
                    (item) => item.id === selectedDashboard.exceptionFilterId
                  )[0]
                : null;
              if (selectedCustomFilter) {
                const customFilter =
                  selectedCustomFilter.data.scoreFilters[filter.title];
                if (customFilter) {
                  garmExceptions[filter.dashboardFilterKey] = {
                    default: getValidGarmScores(customFilter.score),
                  };
                  if (customFilter.content.length) {
                    const content: any = {};
                    customFilter.content.forEach((item: ScoreFilterType) => {
                      if (item.exceptions?.length) {
                        content[
                          item.type === ScoreExceptionTypes.Genre
                            ? 'genre'
                            : 'garmReasoning'
                        ] = item.exceptions;
                      }
                    });
                    garmExceptions[filter.dashboardFilterKey] = {
                      ...garmExceptions[filter.dashboardFilterKey],
                      ...content,
                    };
                  }
                }
              }
            }
          }
        });
      }
      body.garmExceptions = garmExceptions;
      body.electionsExceptions = {
        percentRange: selectedDashboard?.garmFilters?.electionRange || [0, 100],
      };
      fetchReport(body);
    }
  };

  const fetchReport = useCallback(async (body: any) => {
    try {
      setLoading(true);
      const data = await getGarmShowReports(body);
      setSelectedDashboardReport(data);
      setStep(2);
    } catch (error) {
      setSelectedDashboardReport(null);
    } finally {
      setLoading(false);
    }
  }, []);

  const formatReportData = useCallback(
    (data: ReportDataTypes) => {
      return {
        ...data,
        dashboardName: garmDashboards
          ? garmDashboards.find((item) => item.id === data.dashboardId)?.name
          : '',
      };
    },
    [garmDashboards]
  );

  const generateReport = async () => {
    if (dashboard) {
      const riskFilters = cloneDeep(filterMapInit);

      Object.values(dashboard.garmFilters || {}).forEach((filter: any) => {
        const found = Object.entries(filterMapInit).find(
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          ([_, each]) => each.dashboardFilterKey === filter?.dashboardFilterKey
        );
        if (found) {
          riskFilters[found[0]] = {
            ...riskFilters[found[0]],
            ...filter,
            enabled: true,
          };
        }
      });

      const garmScoreColumns: string[] = [];

      Object.keys(riskFilters).forEach((key) => {
        if (riskFilters[key].enabled && riskFilters[key].title) {
          garmScoreColumns.push(riskFilters[key].title);
        }
      });
      const labels = garmScoreColumns;
      let newReportGraphData: ReportGraphType = {
        labels,
        datasets: [],
      };

      const customLabels = labels.map((label: string) => {
        switch (label) {
          case 'Adult & Explicit Sexual Content':
            return ['Adult & Explicit', 'Sexual Content'];
          case 'Obscenity and Profanity':
            return ['Obscenity', 'and Profanity'];
          case 'Debated Sensitive Social issues':
            return ['Debated', 'Sensitive', 'Social issues'];
          case 'Illegal drugs, Tobacco and Alcohol':
            return ['Illegal drugs', 'Tobacco', 'and Alcohol'];
          case 'Online Piracy or Spam':
            return ['Online Piracy', 'or Spam'];
          case 'Death, Injury or Military Conflict':
            return ['Death', 'Injury or', 'Military Conflict'];
          case 'Crime & Human Rights Violations':
            return ['Crime &', 'Human Rights', 'Violations'];
          case 'Hate Speech':
            return ['Hate Speech'];
          case 'Arms & Ammunition':
            return ['Arms &', 'Ammunition'];
          default:
            return label;
        }
      });

      if (Object.keys(riskComponents).length && selectedDashboardReport) {
        newReportGraphData = {
          labels: customLabels as string[],
          datasets: [
            {
              label: 'High',
              data: labels.map(
                (label) =>
                  selectedDashboardReport[riskComponents[label]]['High']
              ),
              backgroundColor: RISK_COLOR_ASSOCIATION['high'],
              borderColor: 'rgb(200, 200, 200)',
              borderWidth: 1,
            },
            {
              label: 'Medium',
              data: labels.map(
                (label) =>
                  selectedDashboardReport[riskComponents[label]]['Medium']
              ),
              backgroundColor: RISK_COLOR_ASSOCIATION['medium'],
              borderColor: 'rgb(200, 200, 200)',
              borderWidth: 1,
            },
            {
              label: 'Low',
              data: labels.map(
                (label) => selectedDashboardReport[riskComponents[label]]['Low']
              ),
              backgroundColor: RISK_COLOR_ASSOCIATION['low'],
              borderColor: 'rgb(200, 200, 200)',
              borderWidth: 1,
            },
            {
              label: 'No',
              data: labels.map(
                (label) => selectedDashboardReport[riskComponents[label]]['No']
              ),
              backgroundColor: RISK_COLOR_ASSOCIATION['norisk'],
              borderColor: 'rgb(200, 200, 200)',
              borderWidth: 1,
            },
            {
              label: 'Still scoring',
              data: labels.map(
                (label) => selectedDashboardReport[riskComponents[label]]['Wip']
              ),
              backgroundColor: RISK_COLOR_ASSOCIATION['nodssi'],
              borderColor: 'rgb(200, 200, 200)',
              borderWidth: 1,
            },
          ],
        };
      }

      setReportGraphData(newReportGraphData);
      setOpenReportGraphModal(true);
      setStep(1);
      setDashboard(null);
      try {
        const data = await createReport({
          dashboardId: dashboard.id,
          data: newReportGraphData,
        });
        setReportsData([...reportsData, formatReportData(data)]);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handleDeleteReport = async (id: string) => {
    try {
      await deleteReport(id);
      setReportsData((data: ReportDataTypes[]) =>
        data.filter((item) => item.id !== id)
      );
    } catch (err) {
      console.log(err);
    }
  };

  const fetchReports = useCallback(async () => {
    try {
      const data = await getAllReports();
      const formatedData = data.map((item: ReportDataTypes) =>
        formatReportData(item)
      );
      setReportsData(formatedData);
    } catch (err) {
      console.log(err);
    }
  }, [formatReportData]);

  useEffect(() => {
    if (garmDashboards) {
      fetchReports();
    }
  }, [garmDashboards]);

  const handleViewReport = (data: ReportGraphType) => {
    setReportGraphData(data);
    setOpenReportGraphModal(true);
  };

  return (
    <Grid container className={classes.layout}>
      <Grid container className={classes.container}>
        <div className={classes.steps}>
          <StepView
            step={1}
            title={'Select Dashboard'}
            description={
              dashboard
                ? `You’ve selected a ${dashboard.name} dashboard, now move on to step 2 below`
                : 'Select the dashboard you would like to generate a report about from list below. Your report will be based on the filters you have saved in that Dashboard.'
            }
            completed={step > 1}
            disabled={false}
            selectingDashboard={loading}
            selectDashboard={() => {
              setOpenModal(true);
            }}
            removeDashboard={() => {
              setDashboard(null);
              setStep(1);
            }}
          />
          <StepView
            step={2}
            title={'Generate Report'}
            description={
              'Once you’re all set with your selection, please select generate report.'
            }
            completed={false}
            disabled={step < 2}
            generateReport={() => generateReport()}
          />
        </div>
        <ReportTable
          data={reportsData}
          deleteReport={handleDeleteReport}
          viewReport={handleViewReport}
        />
        <Modal
          open={openModal}
          onClose={() => setOpenModal(false)}
          aria-labelledby='report-dashboard-modal-title'
          aria-describedby='report-dashboard-modal-description'
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 800,
          }}
        >
          <Box sx={{ ...modalStyle }}>
            <CancelIcon
              className={classes.closeIcon}
              onClick={() => setOpenModal(false)}
            />
            <Typography variant='h4' style={{ padding: 16 }}>
              Select Dashboard
            </Typography>
            <div className={classes.modalContent}>
              {garmDashboards.map((dashboard: Dashboard) => (
                <ListItem
                  button
                  onClick={() => handleSelectDashboard(dashboard)}
                  key={dashboard.id}
                >
                  <ListItemText
                    primary={dashboard.name}
                    primaryTypographyProps={{
                      variant: 'caption',
                    }}
                  />
                </ListItem>
              ))}
            </div>
          </Box>
        </Modal>
        <Modal
          open={openReportGraphModal}
          onClose={() => setOpenReportGraphModal(false)}
          aria-labelledby='report-graph-modal-title'
          aria-describedby='report-graph-modal-description'
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 800,
          }}
        >
          <Box sx={{ ...reportGraphModalStyle }}>
            <CancelIcon
              className={classes.closeIcon}
              onClick={() => setOpenReportGraphModal(false)}
            />
            <Typography variant='h4' style={{ padding: 16 }}>
              {dashboard?.name}
            </Typography>
            {reportGraphData && <ReportGraph data={reportGraphData} />}
          </Box>
        </Modal>
      </Grid>
    </Grid>
  );
}
