import { createStyles, makeStyles } from '@material-ui/core/styles';

import { Theme } from 'src/theme/types/createPalette';
import {
  DRAWER_WIDTH,
  DRAWER_WIDTH_SMALL,
  SECONDARY_DRAWER_WIDTH,
} from 'src/hooks/useSidebar';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: DRAWER_WIDTH,
      zIndex: 2,
      '& .BarameterLogo': {
        marginLeft: 0,
      },
      '.SmallSidebar &': {
        width: DRAWER_WIDTH_SMALL,
      },
    },

    menuContainer: {
      height: 42,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },

    menuOpenIcon: {
      fontSize: 25,
      marginRight: 15,
    },

    logoContainer: {
      paddingTop: 14,
      paddingLeft: 19,
      boxSizing: 'border-box',
    },

    drawerExt: {
      width: DRAWER_WIDTH,
      marginLeft: DRAWER_WIDTH,
      zIndex: 1,
      '.SmallSidebar &': {
        marginLeft: DRAWER_WIDTH_SMALL,
        width: DRAWER_WIDTH_SMALL,
      },
    },

    drawerPaper: {
      width: DRAWER_WIDTH,
      backgroundColor: '#FFFFFF',
      '.SmallSidebar &': {
        width: DRAWER_WIDTH_SMALL,
      },
    },

    drawerPaperSecondary: {
      backgroundColor: '#fff',
      marginLeft: DRAWER_WIDTH,
      paddingTop: theme.spacing(4.375),
      width: SECONDARY_DRAWER_WIDTH,
      '.SmallSidebar &': {
        marginLeft: DRAWER_WIDTH_SMALL,
      },
    },

    link: {
      color: theme.palette.grayText.main,
      cursor: 'pointer',
      fontSize: 20,

      textDecoration: 'none',
    },

    listContainer: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      marginTop: theme.spacing(2),
      paddingBottom: 0,
    },

    listItem: {
      marginBottom: theme.spacing(1),
      paddingLeft: theme.spacing(2.25),
      color: '#212121',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: 16,
      '& span.MuiTypography-body1': {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: 12,
      },
      '& .MuiTypography-colorTextSecondary': {
        color: 'white',
      },
      '& .MuiListItemIcon-root': {
        minWidth: 'auto',
        marginRight: 12,
      },
    },

    logOutButton: {
      padding: 16,
      marginTop: 16,
      '& .MuiListItemIcon-root': {
        marginRight: 0,
      },
      '& .MuiAvatar-root': {
        marginRight: theme.spacing(2),
      },
      marginBottom: 0,
      borderTop: '1px solid var(--gray-200, #EAECF0)',
    },

    logOutSmall: {
      marginRight: 0,
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'row',

      '& .MuiAvatar-root': {
        marginRight: 0,
      },
    },

    active: {
      '& span.MuiTypography-body1': {},
    },

    icon: {
      fontSize: 32,
    },

    itemRightSideIcon: {
      marginRight: '0 !important',
      '& svg': {
        marginLeft: 'auto',
        '& > path': {
          fill: '#667085',
        },
      },
    },
    selectionContainer: {
      position: 'absolute',
      left: '100%',
      top: 0,
      height: '100vh',
      width: '100%',
      maxWidth: 360,
      background: '#ffffff',
      borderRight: '1px solid rgba(0, 0, 0, 0.12)',
      padding: theme.spacing(2),
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      gap: 20,
    },
    selectionMain: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      gap: 4,
      height: 'calc(100% - 50px)',
    },
    selectionHeader: {
      display: 'flex',
      flexDirection: 'column',
      gap: 10,
    },
    selectionOptions: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      gap: 4,
      overflow: 'auto',

      '& .MuiFormControlLabel-root': {
        marginLeft: 0,
      },
      '&::-webkit-scrollbar': {
        width: 6,
      },
      '&::-webkit-scrollbar-track ': {
        background: '#f1f1f1',
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#888',
        borderRadius: 3,
      },
      '&::-webkit-scrollbar-thumb:hover': {
        background: '#555',
      },
    },
    selectOption: {
      display: 'flex',
      alignItems: 'center',
      gap: 4,

      '& > svg': {
        width: 16,
        height: 16,
        cursor: 'pointer',
      },
    },
    selectChildren: {
      marginLeft: 16,
      display: 'flex',
      flexDirection: 'column',
      gap: 4,
      position: 'relative',

      '&::before': {
        content: '""',
        position: 'absolute',
        left: -8,
        width: 1,
        top: 4,
        bottom: 4,
        borderLeft: '1px dashed gray',
      },
    },
    selctionActions: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 20,
    },
    searchContainer: {
      display: 'flex',
      alignItems: 'center',
      padding: 8,
      border: '1px solid rgba(0, 0, 0, 0.12)',
      borderRadius: 20,
      gap: 8,
      transitionProperty: 'all',
      transitionTimingFunction: 'linear',
      transitionDuration: '250ms',

      '& > svg': {
        width: 24,
        height: 24,
      },

      '& > input': {
        height: 24,
        border: 'none',
        padding: 0,
        margin: 0,
        flex: 1,
        outline: 'none',
        fontSize: 16,
      },
    },
    subList: {
      padding: '0 16px',
      display: 'flex',
      flexDirection: 'column',
      gap: 4,

      '& > div': {
        padding: '6px 12px',
        display: 'flex',
        alignItems: 'center',
        gap: 12,
        cursor: 'pointer',

        '& > svg': {
          fill: '#efefef',
          stroke: '#667085',
        },

        '& p': {
          color: '#344054',
        },

        '& .searchShortKey': {
          padding: '2px 6px',
          borderRadius: 6,
          border: '1px solid #D0D5DD',
          background: '#FFF',
          boxShadow: ' 0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
          marginLeft: 'auto',
        },

        '&:hover': {
          borderRadius: 4,
          border: '1px solid #BFD9FA',
          background: '#EFF6FE',

          '& > svg': {
            fill: '#5EA1F2',
            stroke: '#5EA1F2',
          },

          '& p': {
            color: '#5EA1F2',
          },

          '& .searchShortKey': {
            border: '1px solid #BFD9FA',
            background: '#F5FAFF',
          },
        },
      },
    },
  })
);
