/* eslint-disable quotes */
export const GARM8Data = {
  'Illegal Drugs': [
    'Alprazolam',
    'Amphetamine',
    'Amphetamine Sulphate',
    'Anabolic steroids',
    'Angel dust',
    'Ayahuasca',
    'Banshee dust',
    'Bath Salts',
    'Benzo Fury',
    'Benzodiazepines',
    'Benzofuran compounds',
    'Benzos',
    'Benzylpiperazine',
    'Butane',
    'Butylone',
    'BZP',
    'Cannabis',
    'Carfentanyl',
    'Cathinones',
    'Chamisul',
    'Co-codamol',
    'Cocaine',
    'Codeine',
    'Codis500',
    'do Coke',
    'doing coke',
    'on coke',
    'snort coke',
    'snorting coke',
    'snorting cocaine',
    'snort cocaine',
    'does coke',
    'Crackhead',
    'do crack',
    'smoke crack',
    'smokes crack',
    'smoking crack',
    'on crack',
    'Crystal Meth',
    'Desoxypipradrol',
    "Devil's Weed",
    "Devil's lettuce",
    'Diamorphine',
    'Diazepam',
    'Dimethocaine',
    'dirty sprite',
    'DMT',
    'Donkey Dust',
    'drink Lean',
    'Ecstasy',
    'Ethylphenidate',
    'Etizolam',
    'Fenazepam',
    'Fentanyl',
    'Gabapentin',
    'Ganja',
    'Gbh',
    'GHB',
    'Hallucinogen',
    'Hallucinogenic',
    'handle of jameson',
    'Happy Pills',
    'smoke Hash',
    'smoking hash',
    'Hashish',
    'smoke Herb',
    'smoking herb',
    'smoke trees',
    'blow trees',
    'smoke trees',
    'Heroin',
    'Hippie Crack',
    'Image and performance enhancing drug',
    'Image and performance enhancing drug IPEDs',
    'Inhalants',
    'IPEDs',
    'Ket',
    'Ketamine',
    'special k',
    'Laughing Gas',
    'Liberty Caps',
    'Liquid Ecstasy',
    'LSD',
    'Lyrica',
    'M-Smack',
    'Magic mushrooms',
    'MDMA',
    'Melanotan',
    'Meph',
    'Mephedrone',
    'Mescaline',
    'Meth',
    'Methadone',
    'Methamphetamine',
    'Methoxetamine',
    'Methoxetamine MXE',
    'Methylnaphthidate',
    'Methylone',
    'Methylphenidate',
    'pop a molly',
    'take molly',
    'do molly',
    'on molly',
    'doing molly',
    'does molly',
    'Monkey Dust',
    'Morphine',
    'Mthylone',
    'Mushies',
    'Naloxone',
    'Naltrexone',
    'Naphyrone',
    'Narcotic',
    'Neurontin',
    'psychoactive substances',
    'Nitrous oxide',
    'non opioid',
    'non-opioid',
    'nonopioid',
    'Nopaine',
    'Noz',
    'Nrg-1',
    'Nrg1',
    'Nurofen Plus',
    'Nurofen Plus/Max',
    'on lean',
    'drinking lean',
    'drink lean',
    'Opiate',
    'Opioid painkillers',
    'Oskar Blues',
    'Paper Mushrooms',
    'Party Pills',
    'PCP',
    'Peace pills',
    'Pep Love',
    'Pep Stoned',
    'Pep Twisted',
    'perc 30',
    'Percocet',
    'performance enhancing drugs',
    'perk 30',
    'Peyote',
    'Phenazepam',
    'Phencyclidine',
    'Physeptone',
    'on Pills',
    'Pink Ecstasy',
    'Pink Superman',
    'Poppers',
    'smoke Pot',
    'smokes pot',
    'smoking pot',
    'Pregabalin',
    'Promethazine',
    'Propylphenidate',
    'Psychedelic',
    'Psychedelics',
    'Psychoactive Drug',
    'Psychological Dependence',
    'Psychopharmacology',
    'Psychotropic Drug',
    'Puff puff pass',
    'Purple wave',
    'kush',
    'indica',
    'sativa',
    'Pyrovaleron',
    'Pyrovalerone',
    'Qaadka',
    'Qat',
    'Quat',
    'Rhino ket',
    'Ritalin',
    'crack rocks',
    'crack Rock',
    'smoke rocks',
    'smoking rocks',
    'smoke rock',
    'smoking rock',
    'Rohypnol',
    'Roids',
    'Roll-ups',
    'pre-roll',
    'preroll',
    'pre roll',
    'Roofies',
    'Salvia',
    'Sensi',
    'Shisha',
    'Shrooms',
    'Sildenafil',
    'Sinsemilla',
    'sip Lean',
    'Sixpoint Resin',
    'sizzurp',
    'Smart drugs',
    'smoke V eight stick',
    'smoke V8 eight stick',
    'Smokes',
    'do blow',
    'doing blow',
    'Solvents',
    'Special K',
    'take Speed',
    'taking speed',
    'on speed',
    'doing speed',
    'steroids',
    'Super K',
    'Synthetic cannabinoids',
    'Synthetic opioids',
    'T-7',
    'a Tab of',
    'tabs of',
    'Tai High Hawaiian Haze',
    'Temazepam',
    'Tramadol',
    'Tramal',
    'Tranquilizers',
    'Tripstacy',
    'Tryptamines',
    'Uppers',
    'Valium',
    'Vallies',
    'Viagra',
    'Weed',
    'Whippits',
    'Xanax',
    'xanny',
    'xanny bar',
    'Xtc',
    'zanny',
    'zanny bar',
    'Quaaludes',
    'Quaaludes',
    'Cowies',
    'Downers',
    'Freebase',
    'Indopan',
    'Linctus',
    'Yaba',
  ],
  Alcohol: [
    'a bottle of fireball',
    'a bottle of Malibu',
    'a cup of Malibu',
    'a glass of fireball',
    'A handle of',
    'a handle of fireball',
    'a handle of Malibu',
    'Absolut',
    'Alcohol',
    'ale',
    'Allagash White',
    'Altbier',
    'Amaretto Sour',
    'Amber ale',
    'Amber Moon',
    'Amstel Light',
    'Amsterdam Gold',
    'An Old Fashioned',
    'Aperol Spritz',
    'Appletini',
    'arak',
    'arak',
    'arrack',
    'Bacardi',
    'Baccy',
    'baijiu',
    "Bailey's",
    'Baileys',
    "Ballantine's",
    'Ballast Point',
    'barley wine',
    'barleywine',
    'Beck’s',
    'Beef eater',
    'Beefeater',
    'beer',
    'Belgian White',
    'Bell’s Two Hearted Ale',
    'Bellini',
    'Berliner Weisse',
    'Bevvy',
    'Bhang',
    'Bière de Garde',
    'bitters',
    'Black Mamba',
    'Black Russian',
    'Blonde Ale',
    'Blood & Sand',
    'Bloody Mary',
    'Blotter',
    'Blue Moon',
    'Bombay Blue Extreme',
    'Bombay Sapphire',
    'Booze',
    'bottle of jack',
    'bourbon',
    'brandy',
    'Breckenridge Brewery',
    'Brooklyn Lager',
    'Brown ale',
    'Brugal',
    'Bud Light',
    'Bud Light Lime',
    'Bud Platinum',
    'Budweiser',
    'Bulleit',
    'Busch Light',
    'Caipirinha',
    'California Common',
    'Campari',
    'Captain Morgan',
    'Casamigos',
    'Cigar City Brewing',
    'Ciroc',
    'Clan Macgregor',
    'cognac',
    'Coors',
    'Coors Banquet',
    'Coors Light',
    'Corona Extra',
    'Corona Light',
    'Corona Premier',
    'Coronita',
    'Courvoisier',
    'Cream Ale',
    'Daiquiri',
    'Dogfish Head',
    'Don Julio',
    'Dos Equis Lager',
    'Dry Dock Brewing',
    'El Jimador',
    'Elysian Brewing',
    'Fat Tire',
    'Fireball',
    'Fireball whiskey',
    'Fireball whisky',
    'Firestone Walker',
    'Flanders red ale',
    'Fruit beer',
    'Gentleman Jack',
    'gin',
    'Gin Fizz',
    'glass of jameson',
    'Glenfiddich',
    "Glenlivet Founder's Reserve",
    'Golden Road Brewing',
    'Summer ale',
    'Golden ale',
    'Gordon’s Gin',
    'Gray Goose',
    'Grey Goose',
    'Guinness',
    'Guinness Draught',
    'Guinness Extra Stout',
    'Harpoon IPA',
    'Havana Club',
    'Hefeweizen',
    'Heineken',
    'Helles',
    "Hendrick's",
    'Hennessy',
    'Herb and spiced beer',
    'Hornitos',
    'India pale ale',
    'IPA',
    'Irish Car Bomb',
    'Irish Coffee',
    'Irish Cream',
    'Irish whiskey',
    'irish up',
    'Jack and Coke',
    'Jack Daniels',
    'Jägerbomb',
    'Jagermeister',
    'Jim Beam',
    'Jim Beam Family',
    'Johnnie Walker',
    'Jose Cuervo',
    'Jose Cuervo RTD',
    'Kahlua',
    'Ketel One',
    'Kölsch',
    'Kona Big Wave Golden Ale',
    'Lager',
    'Lagunitas',
    'LandShark Lager',
    'Left Hand Milk Stout',
    'ale',
    'liquor',
    'Little Sumpin’ Sumpin’ Ale',
    'Long Island Iced Tea',
    'macallan 12',
    'macallan 18',
    'macallan 25',
    'macallan eighteen',
    'macallan twelve',
    'macallan twenty-five',
    'Mai Tai',
    'Maine Beer Co',
    'Maine Beer Co.',
    'Maine Beer Company',
    'Maker’s Mark',
    'Malt liquor',
    'Margarita',
    'Marijuana',
    'Martini',
    'mead',
    'mezcal',
    'Michelada',
    'Michelob ULTRA',
    'Miller High Life',
    'Miller Lite',
    'Mimosa',
    'Mint Julep',
    'Modelo',
    'Modelo Especial',
    'Modelo Negra',
    'Mojito',
    'moonshine',
    'Moscow Mule',
    'Narragansett',
    'natty light',
    'Natural Ice',
    'Natural Light',
    'Negroni',
    'New Amsterdam',
    'New Belgium',
    'New Holland Dragon’s Milk Bourbon Barrel Stout',
    'Octoberfest',
    'Odell 90 Shilling Ale',
    'Oktoberfestbier/Märzenbier',
    'Old ale',
    'Old Cuban',
    'Pabst Blue Ribbon',
    'Pacifico Clara',
    'Pale ale',
    'palm wine',
    'PBR',
    'Pilsener',
    'Pilsner',
    'Pilsner Urquell',
    'Pina Colada',
    'Pisco Punch',
    'Pisco Sour',
    'pop bottles',
    'pop champagne',
    'popping bottles',
    'raki',
    'Ram Bevvy',
    'Ramos Gin Fizz',
    'Red ale',
    'Red Stripe',
    'Remy Martin',
    'Revolver Brewing',
    'Roggenbier',
    'rum',
    'RumChata',
    'Rye Beer',
    'Saison',
    'Sake Bomb',
    'sambuca',
    'Samuel Adams',
    'Sauza',
    'Sazerac',
    'Schöfferhofer Grapefruit Hefeweizen\t',
    'Schwarzbier',
    'Scotch ale',
    'Scotch and soda',
    'Scotch whisky',
    'Screwball',
    'Sculpin',
    'Sex on the Beach',
    'Shiner Bock',
    'shochu',
    'Sidecar',
    'Sloop Brewing',
    'Smirnoff',
    'soju',
    'some jameson',
    'Southern Comfort',
    'Station 26 Juicy Banger IPA',
    'Stella Artois',
    'Stolichnaya',
    'Stone Delicious IPA',
    'Stone IPA',
    'Svedka',
    'Tanqueray',
    'Tecate',
    'Tennessee Honey',
    'Tennessee Rye',
    'tequila',
    'The Glenlivet',
    'Ti’ Punch',
    'beer',
    'vermouth',
    'Victory Brewing',
    'Vienna lager',
    'Vieux Carré',
    'vodka',
    'Vodka Martini',
    'Voodoo Ranger',
    'Weihenstaphaner',
    'Weissbier',
    'Weizenbock',
    'Wheat Beer',
    'whiskey',
    'Whiskey Sour',
    'whisky',
    'White Russian',
    'Wild beer',
    'drink wild turkey',
    "William Lawson's",
    'wine',
    'Witbier',
    'Wood-aged beer',
    'Wuliangye',
    'Yuengling',
    'Yuengling Light Lager',
    'Chivas Regal',
    'Clover Club',
    'Corpse Reviver',
    'Corpse Reviver #2',
    'Cosmic Kelly',
    'Crown Royal',
    'Cuba Libre',
    "D'Usse",
    "Dewar's",
    'Doppelbock',
    'Dortmunder Export',
    'Eisbock',
    'Famous Grouse',
    'French 75',
    'Golden Monkey',
    'Gose',
    'Gueuze',
    'Kaoliang',
    'Lambic',
    'Luzhou Laojiao',
    'Maotai',
    'McDowell’s No.1',
    'Peroni Nastro Azzurro',
  ],
  'Tobacco/Vaping': [
    'a Basic cigarette',
    'a Cambridge cigarette',
    'a Camel cigarette',
    'a Capri cigarette',
    'a carton of Ashford',
    'a carton of Basic',
    'a carton of Cambridge',
    'a carton of Camel',
    'a carton of Capri',
    'a carton of Chesterfield',
    'a carton of Crossroads',
    'a carton of Dakota',
    'a carton of Doral',
    'a carton of Eclipse',
    'a carton of Eve',
    'a carton of Everest',
    'a carton of Kent',
    'a carton of Kool',
    'a carton of L',
    'a carton of Lark',
    'a carton of Liggett',
    'a carton of Lucky',
    'a carton of Marlboro',
    'a carton of Maverick',
    'a carton of Max',
    'a carton of Merit',
    'a carton of Misty',
    'a carton of More',
    'a carton of Newport',
    'a carton of Oasis',
    'a carton of Parliament',
    'a carton of Philip',
    'a carton of Pyramid',
    'a carton of Salem',
    'a carton of Strand',
    'a carton of Tareyton',
    'a carton of Thang',
    'a carton of TRUE',
    'a carton of USA',
    'a carton of Vantage',
    'a carton of Viceroy',
    'a carton of Virginia',
    'a carton of Winston',
    'a Chesterfield cigarette',
    'a Crossroads cigarette',
    'a Dakota cigarette',
    'a Kent cigarette',
    'a Kool cigarette',
    'a L&M cigarette',
    'a Lark cigarette',
    'a Liggett Select cigarette',
    'a Lucky Strike cigarette',
    'a tab of lucy',
    'a Marlboro cigarette',
    'a Maverick cigarette',
    'a Max cigarette',
    'a Merit cigarette',
    'a Misty cigarette',
    'a More cigarette',
    'a Newport cigarette',
    'a pack of Ashford',
    'a pack of Basic',
    'a pack of Cambridge',
    'a pack of Camel',
    'a pack of Capri',
    'a pack of Chesterfield',
    'a pack of Crossroads',
    'a pack of Dakota',
    'a pack of Doral',
    'a pack of Eclipse',
    'a pack of Eve',
    'a pack of Everest',
    'a pack of Kent',
    'a pack of Kool',
    'a pack of L',
    'a pack of Lark',
    'a pack of Liggett',
    'a pack of Lucky',
    'a pack of Marlboro',
    'a pack of Maverick',
    'a pack of Max',
    'a pack of Merit',
    'a pack of Misty',
    'a pack of More',
    'a pack of Newport',
    'a pack of Oasis',
    'a pack of Parliament',
    'a pack of Philip',
    'a pack of Pyramid',
    'a pack of Salem',
    'a pack of Strand',
    'a pack of Tareyton',
    'a pack of Thang',
    'a pack of TRUE',
    'a pack of USA',
    'a pack of Vantage',
    'a pack of Viceroy',
    'a pack of Virginia',
    'a pack of Winston',
    'a Parliament cigarette',
    'a Philip Morris cigarette',
    'a Pyramid cigarette',
    'a Salem cigarette',
    'a Strand cigarette',
    'a Tareyton cigarette',
    'a Thang Long cigarette',
    'a TRUE cigarette',
    'a USA cigarette',
    'a USA Gold cigarette',
    'a Vantage cigarette',
    'a Viceroy cigarette',
    'a Virginia Slims cigarette',
    'a Winston cigarette',
    'an Ashford cigarette',
    'an Doral cigarette',
    'an Eclipse cigarette',
    'an Eve cigarette',
    'an Everest cigarette',
    'an Oasis cigarette',
    'Ashford cigarettes',
    'Basic cigarettes',
    'box mod',
    'Cambridge cigarettes',
    'Camel cigarettes',
    'Capri cigarettes',
    'Chesterfield cigarettes',
    'cigar',
    'cigarette',
    'cigarettes',
    'cigars',
    'Ciggies',
    'cigs',
    'Crossroads cigarettes',
    'Dakota cigarettes',
    'Doral cigarettes',
    'E juice',
    'e-juice',
    'Eclipse cigarettes',
    'Eve cigarettes',
    'Everest cigarettes',
    'geek vape',
    'vape',
    'Hookah',
    'juul',
    'Kent cigarettes',
    'Kool cigarettes',
    'L&M cigarettes',
    'Lark cigarettes',
    'Liggett Select cigarettes',
    'Lucky Strike cigarettes',
    'Marlboro cigarettes',
    'Marlboro red',
    'Marlboro reds',
    'Maverick cigarettes',
    'Max cigarettes',
    'Merit cigarettes',
    'Misty cigarettes',
    'More cigarettes',
    'Natural American Spirit',
    'Newport cigarettes',
    'nick stick',
    'nicotine',
    'Oasis cigarettes',
    'Parliament cigarettes',
    'Philip Morris cigarettes',
    'Pyramid cigarettes',
    'Salem cigarettes',
    'Strand cigarettes',
    'Tareyton cigarettes',
    'Thang Long cigarettes',
    'Tobacco',
    'TRUE cigarettes',
    'USA cigarettes',
    'USA Gold cigarettes',
    'Vantage cigarettes',
    'vape',
    'vaping',
    'vaporesso',
    'Viceroy cigarettes',
    'Virginia Slims cigarettes',
    'Winston cigarettes',
  ],
  'Related to Illicit Substances': [
    'chemical withdrawal',
    'drinker',
    'delirium tremens',
    'drug Addict',
    'drug Addiction',
    'Drug Misuse',
    'DUI',
    'Dunkel',
    'Dunkelweizen',
    'DWI',
    'Nootropics',
    'off the loud',
    'rehab',
    'relapse prevention',
    'wook',
    'hangover',
    'hungover',
  ],
};

export const GARM11Data = [
  'Affordable Care Act',
  'Alt-right',
  'Antifa',
  'Affirmative Action',
  "America's global influence",
  'Assisted suicide',
  'Animal abuse',
  'Abortion',
  'Black Lives Matter',
  'Border security',
  'Bilingual education',
  'Bias and discrimination',
  'Coronavirus',
  'Cybersecurity',
  'Capital punishment',
  'Censorship',
  'Childhood obesity',
  'Civil rights',
  'Climate change',
  'Criminal Justice',
  'Cyber bullying',
  'Child abuse',
  'Child molestation',
  'Church and state separation',
  'DACA',
  'Drug abuse',
  'Drug legalization',
  'Early voting',
  'Equal Rights Amendment',
  'Executive order',
  'Extremism',
  'Environmental health',
  'Early pregnancy',
  'Factory farming',
  'Foreign aid',
  'Fracking',
  'Freedom of speech',
  'Female genital mutilation',
  'General Data Protection Regulation',
  'Gerrymandering',
  'Genetic engineering',
  'Green New Deal',
  'Government regulations',
  'Gay Marriage',
  'Same-Sex Marriage',
  'Gender Identity',
  'Gun control',
  'Gun legislation',
  'Green energy',
  'Hate speech',
  'Health care reform',
  'Human trafficking',
  'campaign Hacking',
  'Health care access',
  'Israel Palestine relations',
  'Immigration',
  'Islamophobia',
  'Labor unions',
  'LGBTQ Adoption Rights',
  'Me Too movement',
  'Misinformation',
  'Minimum wage',
  'Mental illness',
  'Medicare',
  'Medicaid',
  'Net neutrality',
  'Nuclear energy',
  'Offshore drilling',
  'Obamacare',
  'Obesity',
  'Police reform',
  'Prescription drug addiction',
  'Privacy and Surveillance',
  'Policing police',
  'Russian hacking',
  'Racial profiling',
  'Reparations',
  'Racism',
  'Religion Discrimination',
  'Sanctuary city',
  'Socialism',
  'Stimulus packages',
  'Supreme Court confirmation',
  'Standardized testing',
  'Same-sex marriage',
  'Sexual harassment',
  'Social security reform',
  'Social commentary art',
  'Trade tariffs',
  'Transgender rights',
  'Title IX',
  'Vaccination',
  'Voter fraud',
  'Voting integrity',
  'Voting laws',
  'Violence in the media',
  'White nationalism',
  'Women rights',
  'Water rights',
  'Water supply policy',
  'Water supply regulation',
  'Women Empowerment',
  'Welfare and welfare reform',
  'Weapons of mass destruction',
  'Xenophobia and nativism',
  'Zero tolerance policies',
  'No poverty',
  'Poverty eradication',
  'Child poverty',
  'No hunger',
  'Food Desert',
  'Sustainable transportation',
  'Quality Education',
  'equal education',
  'basic school infrastructure',
  'School completion rate',
  'Gender equality',
  'Water and sanitation',
  'Affordable energy',
  'clean energy',
  'Fossil fuel',
  'Natural Gas',
  'Decent work and economic growth',
  'Green economy',
  'Sustainable tourism',
  'Sustainable cities and communities',
  'Disaster risk reduction',
  'Chemicals and waste',
  'Sustainable consumption and production',
  'Climate action',
  'protect ocean and seas',
  'protect Biodiversity and ecosystems',
  'Protect forest',
  'Protect mountains',
  'Protect river',
  'Protect lake',
  'Water pollution',
  'Air pollution',
  'Desertification land degradation and drought',
  'Migration due to climate crisis',
  'first amendment',
  'Supreme Court',
  'Cancel Culture',
  'disinformation',
  'regulation of the platforms',
  'regulating platforms',
  'second amendment',
  'Mask Mandates',
  'vaccine',
  'Religious Freedom',
  'universal health insurance',
  'Defund police',
  'pro-life',
  'pro-choice',
  'Racial Justice',
  'Older People Rights',
  'Disability Rights',
  'Women’s and Girls’ Health and Rights',
  'Climate change policy and impacts',
  'Criminal Legal System',
  'Children in the Criminal and Juvenile Justice Systems',
  'Sexual Orientation and Gender Identity',
  'Animal abuse',
  'Child abuse',
  'Child molestation',
  'Drug abuse',
  'Drug legalization',
  'Factory farming',
  'Female genital mutilation',
  'General Data Protection Regulation',
  'Genetic engineering',
  'Mental illness',
  'Net neutrality',
  'obesity',
  'Prescription drug addiction',
  'Privacy and Surveillance',
  'Smart speakers',
  'Social commentary art',
  'Water and sanitation',
  'Affordable energy',
  'Russia Ukraine war',
  'Ukraine Russia war',
];

export const GramReasoningData = {
  garm8: GARM8Data,
  garm11: GARM11Data,
};
