import { useHistory } from 'react-router-dom';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Box, TextField, Typography } from '@material-ui/core';
import classNames from 'classnames';

import Button from 'src/components/Button';
import { ROUTES } from 'src/utils';
import { useCommonStyles } from './utils/useCommonStyles';

import waveImg from 'src/assets/landing/wave.png';
import { ReactComponent as LogoIcon } from 'src/assets/landing/logo.svg';
import { ReactComponent as LinkedinIcon } from 'src/assets/socials/linkedin.svg';

import pdfPrivacyPolicy from 'src/assets/PrivacyPolicy.pdf';
import pdfTermsConditions from 'src/assets/TermsConditions.pdf';
import pdfPerComponentForSharing from 'src/assets/PerComponentForSharing.pdf';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      overflow: 'hidden',
      width: 'calc(100% - 8px)',
      margin: '0 4px',
      background: '#202226',
      borderRadius: 2,
      position: 'relative',
      paddingTop: 56,
      paddingBottom: 172,
      height: 840,
      display: 'flex',
      flexDirection: 'column',
      gap: 100,

      '@media screen and (min-width: 768px)': {
        justifyContent: 'space-between',
        width: 'calc(100% - 32px)',
        margin: '0 16px',
        paddingTop: 65,
        paddingBottom: 96,
        height: 700,
      },
    },
    mainContainer: {
      width: '100%',
      maxWidth: 1600,
      margin: '0 auto',
      padding: '0 16px',
      display: 'flex',
      flexDirection: 'column',
      gap: 68,
      position: 'relative',

      '@media screen and (min-width: 768px)': {
        flexDirection: 'row',
        alignItems: 'flex-end',
        justifyContent: 'space-between',
        padding: '0 100px',
      },

      '& > div': {
        flex: 1,
      },
    },
    email: {
      width: '100%',
      '& .MuiInputBase-input': {
        fontWeight: 400,
        fontSize: 20,
        lineHeight: '32px',
        color: '#EFEDEB',

        '@media screen and (min-width: 768px)': {
          fontSize: 25,
          lineHeight: '40px',
        },
      },

      '& .MuiInput-underline:before': {
        borderBottomColor: '#545454',
      },
    },
    subScribeButton: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 64,
      height: 64,
      borderRadius: '100%',
      cursor: 'pointer',
      background:
        'linear-gradient(259.81deg, #E671A9 -4.16%, #9873CB 49.59%, #7570DF 84.28%, #736FD5 126.47%)',

      '& .MuiTypography-root': {
        fontWeight: 700,
        fontSize: 30,
        lineHeight: '40px',
        textAlign: 'center',
        textTransform: 'uppercase',
        color: '#EFEDEB',

        '@media screen and (min-width: 768px)': {
          fontWeight: 500,
          fontSize: 32,
          lineHeight: '32px',
        },
      },
    },
    subscribeGradient: {
      position: 'absolute',
      left: 0,
      top: -15,
      background:
        'linear-gradient(259.81deg, #E671A9 -4.16%, #9873CB 49.59%, #7570DF 84.28%, #736FD5 126.47%)',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
      backgroundClip: 'text',
      textFillColor: 'transparent',

      '@media screen and (min-width: 768px)': {
        top: -30,
      },
    },
    socialIcon: {
      width: 40,
      height: 40,

      '@media screen and (min-width: 768px)': {
        width: 64,
        height: 64,
      },
    },
    links: {
      width: '100%',
      maxWidth: 460,
      display: 'flex',
      flexDirection: 'column',
      columnGap: 16,
      rowGap: 40,

      '@media screen and (min-width: 768px)': {
        flexDirection: 'row',
      },

      '& > div': {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: 16,

        '@media screen and (min-width: 768px)': {
          gap: 8,
        },
      },
    },
    linkButton: {
      width: 220,
      height: 'auto',
      padding: 0,
      justifyContent: 'flex-start',
    },
    backgroundImg: {
      position: 'absolute',
      transform: 'rotate(90deg)',
      maxWidth: 670,
      top: 450,
      right: -250,

      '@media screen and (min-width: 768px)': {
        maxWidth: 1300,
        transform: 'rotate(0)',
        flexDirection: 'row',
        right: 0,
        top: 148,
      },
    },
    info: {
      fontWeight: 500,
      fontSize: 16,
      lineHeight: '24px',
      color: '#EFEDEB',
      position: 'absolute',
      left: 16,
      bottom: -154,

      '@media screen and (min-width: 768px)': {
        left: 100,
        bottom: -78,
      },
    },
  })
);

const Footer: React.FC = () => {
  const classes = useStyles();
  const commonStyles = useCommonStyles();
  const history = useHistory();

  const handleEnterEmail = () => {
    const registerFormLaunchButton = document.getElementById(
      'newsletter-email-capture'
    );

    if (!registerFormLaunchButton) return;

    registerFormLaunchButton.click();
  };

  const handleSpotlightRedirectToDemo = () => {
    if (window.location.host === 'www.thebarometer.co') {
      window.location.assign('https://app.thebarometer.co/spotlight');
    } else {
      history.push(ROUTES.SPOTLIGHT);
    }
  };

  const handleRedirectToLogin = () => {
    if (window.location.host === 'www.thebarometer.co') {
      window.location.assign('https://app.thebarometer.co/login');
    } else {
      history.push(ROUTES.LOGIN);
    }
  };

  const handleRedirectToTeams = () => {
    history.push(ROUTES.TEAM);
  };

  const customLinkInNewTab = async (href: string) => {
    const link = document.createElement('a');
    link.href = href;
    link.rel = 'noreferrer';
    link.target = '_blank';
    document.body.appendChild(link);
    await link.click();
    document.body.removeChild(link);
  };

  return (
    <div className={classes.container}>
      <img src={waveImg} alt='wave' className={classes.backgroundImg} />
      <div className={classes.mainContainer}>
        <LogoIcon
          fill='#EFEDEB'
          width={125}
          cursor='pointer'
          onClick={() => {
            history.push(ROUTES.LANDING);
          }}
        />
      </div>
      <Box className={classes.mainContainer}>
        <Box>
          <Box
            width={1}
            position='relative'
            maxWidth={574}
            display='flex'
            alignItems='center'
            gridGap='24px'
          >
            <Box flex={1}>
              <TextField
                id='enter email'
                placeholder='Enter your email'
                className={classes.email}
              />
            </Box>
            <Box
              className={classes.subScribeButton}
              onClick={() => handleEnterEmail()}
            >
              <Typography>⟩</Typography>
            </Box>
            <Typography
              className={classNames(
                commonStyles.headline1,
                classes.subscribeGradient
              )}
            >
              Subscribe
            </Typography>
          </Box>
          <Box
            display='flex'
            alignItems='center'
            gridGap='16px'
            marginTop='40px'
          >
            <a
              href='https://www.linkedin.com/company/thebarometer/'
              rel='noreferrer'
              target='_blank'
            >
              <LinkedinIcon cursor='pointer' className={classes.socialIcon} />
            </a>
          </Box>
        </Box>
        <Box className={classes.links}>
          <Box>
            <Typography
              style={{ textTransform: 'uppercase', fontWeight: 700 }}
              className={commonStyles.callout1}
            >
              Product
            </Typography>
            {/** temporarily hide the Spotlight page */}
            {/* <Button
              variant='text'
              className={classNames(classes.linkButton, commonStyles.callout1)}
              onClick={handleSpotlightRedirectToDemo}
            >
              Spotlight
            </Button> */}
            <Button
              variant='text'
              className={classNames(classes.linkButton, commonStyles.callout1)}
              onClick={handleRedirectToTeams}
            >
              Our Mission
            </Button>
            <Button
              variant='text'
              className={classNames(classes.linkButton, commonStyles.callout1)}
              onClick={handleRedirectToLogin}
            >
              Login
            </Button>
            <Button
              variant='text'
              className={classNames(classes.linkButton, commonStyles.callout1)}
              onClick={() => {
                customLinkInNewTab(pdfPerComponentForSharing);
              }}
            >
              Definitions
            </Button>
          </Box>
          <Box>
            <Typography
              style={{ textTransform: 'uppercase', fontWeight: 700 }}
              className={commonStyles.callout1}
            >
              Company
            </Typography>
            <Button
              variant='text'
              className={classNames(classes.linkButton, commonStyles.callout1)}
              onClick={() => {
                customLinkInNewTab('mailto:info@thebarometer.co');
              }}
            >
              info@thebarometer.co ↗
            </Button>
            <Button
              variant='text'
              className={classNames(classes.linkButton, commonStyles.callout1)}
              onClick={() => {
                customLinkInNewTab(pdfPrivacyPolicy);
              }}
            >
              privacy policy
            </Button>
            <Button
              variant='text'
              className={classNames(classes.linkButton, commonStyles.callout1)}
              onClick={() => {
                customLinkInNewTab(pdfTermsConditions);
              }}
            >
              terms & conditions
            </Button>
          </Box>
        </Box>
        <Typography className={classes.info}>
          © {new Date().getFullYear()} Barometer, Inc. All rights reserved.
        </Typography>
      </Box>
    </div>
  );
};

export default Footer;
