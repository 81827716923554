import { useState, useMemo, useEffect } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';

import TextInput from 'src/components/TextInput';
import Button from 'src/components/Button';
import { FilterActions } from './FilterActions';
import {
  BrandCustomFilter,
  FilterTypes,
  PodcastExceptionTypes,
  PodcastsFilterType,
  ScoreFilterType,
} from 'src/types/filter';
import {
  createExceptionFilter,
  updateExceptionFilter,
  deleteExceptionFilter,
} from 'src/apis/exception';
import { FilterTypeContainer } from './FilterTypeContainer';
import { GarmScoreFilters } from './GarmScoreFilters';
import { PodcastsFilters } from './PodcastsFilters';
import useAuth from 'src/hooks/useAuth';
import { getGarmDashboards } from 'src/utils';

import { ReactComponent as ArrowLeftIcon } from 'src/assets/icons/arrowLeft.svg';
import { ReactComponent as DashboardIcon } from 'src/assets/dashboard/logo.svg';
import { ReactComponent as EditIcon } from 'src/assets/icons/edit.svg';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      height: '100%',
      background: '#fff',
      display: 'flex',
      flexDirection: 'column',
    },
    header: {
      display: 'flex',
      flexDirection: 'column',
      padding: '24px 16px',
      gap: 16,
      borderBottom: '1px solid #E1E1E3',
    },
    title: {
      fontSize: 20,
      fontWeight: 600,
      lineHeight: '32px',
    },
    description: {
      padding: '2px 8px',
      borderRadius: 4,
      border: '1px solid #C3C3C7',
      width: 545,

      '& .MuiInputBase-root:before, & .MuiInputBase-root:after': {
        border: 'none !important',
      },
    },
    button: {
      borderRadius: 8,
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '20px',
      padding: '0 16px',
      height: 36,
      textTransform: 'capitalize',
    },
    usedDashboard: {
      display: 'flex',
      alignItems: 'center',
      gap: 2,
    },
    content: {
      display: 'flex',
      flex: 1,
    },
    filterTypeContent: {
      flex: 1,
      padding: 24,
      overflow: 'auto',
      background: '#F0F0F1',
    },
  })
);

const DefaultPodcastFilters = [
  {
    type: PodcastExceptionTypes.Include,
    exceptions: [],
  },
  {
    type: PodcastExceptionTypes.Exclude,
    exceptions: [],
  },
];

const DefaultScoreFilters = {
  'Adult & Explicit Sexual Content': {
    score: [0, 3],
    content: [],
  },
  'Obscenity and Profanity': {
    score: [0, 3],
    content: [],
  },
  'Debated Sensitive Social issues': {
    score: [0, 3],
    content: [],
  },
  'Illegal drugs, Tobacco and Alcohol': {
    score: [0, 3],
    content: [],
  },
  'Arms & Ammunition': {
    score: [0, 3],
    content: [],
  },
  'Online Piracy or Spam': {
    score: [0, 3],
    content: [],
  },
  Terrorism: {
    score: [0, 3],
    content: [],
  },
  'Death, Injury or Military Conflict': {
    score: [0, 3],
    content: [],
  },
  'Crime & Human Rights Violations': {
    score: [0, 3],
    content: [],
  },
  'Hate Speech': {
    score: [0, 3],
    content: [],
  },
  Gambling: {
    score: [0, 3],
    content: [],
  },
  Occult: {
    score: [0, 3],
    content: [],
  },
  'Natural Disasters': {
    score: [0, 3],
    content: [],
  },
  'General Election': {
    score: [0, 3],
    content: [],
  },
};

interface FilterDetailsPageProps {
  filter: BrandCustomFilter;
  handleDuplicate: () => void;
  handleBack: () => void;
}

export const FilterDetailsPage = ({
  filter,
  handleDuplicate,
  handleBack,
}: FilterDetailsPageProps) => {
  const classes = useStyles();
  const [changeTitle, setChangeTitle] = useState<boolean>(false);
  const [title, setTitle] = useState<string>(filter.name);
  const [description, setDescription] = useState<string>(filter.description);
  const [hideDetails, setHideDetails] = useState<boolean>(false);
  const [filterType, setFilterType] = useState<FilterTypes>(FilterTypes.GARM);

  const [podcastFilters, setPodcastFilters] = useState<PodcastsFilterType[]>(
    filter.data.podcastFilters || DefaultPodcastFilters
  );

  const [scoreFilters, setScoreFilters] =
    useState<Record<string, { score: number[]; content: ScoreFilterType[] }>>(
      DefaultScoreFilters
    );

  const { organization } = useAuth();
  const garmDashboards = useMemo(
    () => (organization ? getGarmDashboards(organization) : []),
    [organization]
  );

  useEffect(() => {
    if (filter.data.scoreFilters) {
      const temp = { ...DefaultScoreFilters };
      Object.keys(DefaultScoreFilters).forEach((key) => {
        temp[key as keyof typeof DefaultScoreFilters] =
          filter.data.scoreFilters[key] ||
          DefaultScoreFilters[key as keyof typeof DefaultScoreFilters];
      });
      setScoreFilters(temp);
    } else {
      setScoreFilters(DefaultScoreFilters);
    }
  }, [filter]);

  const handleChangeTitle = (value: string) => {
    setTitle(value);
  };

  const handleChangeDescription = (value: string) => {
    setDescription(value);
  };

  const handleSave = () => {
    const scoreFiltersPayload: Record<
      string,
      { score: number[]; content: ScoreFilterType[] }
    > = {};
    Object.keys(scoreFilters).forEach((key) => {
      scoreFiltersPayload[key] = {
        ...scoreFilters[key],
        content: scoreFilters[key]?.content.filter(
          (item) => !!item.exceptions?.length
        ),
      };
    });
    if (filter.id) {
      updateExceptionFilter(filter.id, {
        name: title,
        description: description,
        data: {
          podcastFilters,
          scoreFilters: scoreFiltersPayload,
        },
      }).then(() => {
        handleBack();
      });
    } else {
      createExceptionFilter({
        name: title,
        description: description,
        data: {
          podcastFilters,
          scoreFilters: scoreFiltersPayload,
        },
      }).then(() => {
        handleBack();
      });
    }
  };

  const handleRemove = () => {
    if (filter.id) {
      deleteExceptionFilter(filter.id).then(() => {
        handleBack();
      });
    } else {
      handleBack();
    }
  };

  const handleDuplicateRefresh = () => {
    setTitle('Copy of ' + filter.name);
    setDescription(filter.description);
    setHideDetails(false);
    setChangeTitle(false);
    setFilterType(FilterTypes.GARM);
    handleDuplicate();
  };

  return (
    <Grid className={classes.container}>
      <div className={classes.header}>
        <Grid container direction='row' justifyContent='space-between'>
          <div style={{ display: 'flex', alignItems: 'center', gap: 16 }}>
            <ArrowLeftIcon cursor='pointer' onClick={handleBack} />
            {changeTitle ? (
              <TextInput
                value={title}
                onChange={handleChangeTitle}
                onEnter={() => setChangeTitle(false)}
                className={classes.title}
              />
            ) : (
              <Typography variant='h5' className={classes.title}>
                {title}
              </Typography>
            )}
            <EditIcon
              cursor='pointer'
              onClick={() => setChangeTitle(!changeTitle)}
            />
            <Button
              color='primary'
              variant='text'
              title={hideDetails ? 'Show Details' : 'Hide Details'}
              style={{ fontWeight: 400, fontSize: 14, padding: 0, height: 26 }}
              onClick={() => setHideDetails(!hideDetails)}
            />
          </div>
          <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
            <Button
              className={classes.button}
              color='primary'
              disableElevation
              variant='outlined'
              style={{ color: '#2D2D38', borderColor: '#C3C3C7' }}
              onClick={handleBack}
            >
              Cancel
            </Button>
            <Button
              className={classes.button}
              color='primary'
              disableElevation
              variant='contained'
              onClick={handleSave}
            >
              Save
            </Button>
            <FilterActions
              hasContainer
              handleDuplicate={handleDuplicateRefresh}
              handleRemove={handleRemove}
            />
          </div>
        </Grid>
        {hideDetails ? null : (
          <Grid
            container
            direction='column'
            style={{ gap: 8, paddingLeft: 40 }}
          >
            <div>
              <Typography variant='body1' style={{ fontSize: 12 }}>
                Used in
              </Typography>
              <Grid container direction='row' style={{ gap: 16 }}>
                {filter.dashboardIds.map((dashboardId) => (
                  <div key={dashboardId} className={classes.usedDashboard}>
                    <DashboardIcon />
                    <Typography
                      variant='caption'
                      align='left'
                      style={{
                        fontSize: 14,
                        lineHeight: '20px',
                        color: '#5EA1F2',
                      }}
                    >
                      {
                        garmDashboards.filter(
                          (item) => item.id === dashboardId
                        )[0]?.name
                      }
                    </Typography>
                  </div>
                ))}
              </Grid>
            </div>
            <div>
              <Typography variant='body1' style={{ fontSize: 12 }}>
                Description
              </Typography>
              <TextInput
                value={description}
                onChange={handleChangeDescription}
                className={classes.description}
              />
            </div>
          </Grid>
        )}
      </div>
      <div className={classes.content}>
        <FilterTypeContainer
          selectedType={filterType}
          exceptions={[3, 1]}
          onClick={setFilterType}
        />
        <div className={classes.filterTypeContent}>
          {filterType === FilterTypes.Podcasts ? (
            <PodcastsFilters
              filters={podcastFilters}
              setFilters={setPodcastFilters}
            />
          ) : (
            <GarmScoreFilters
              filters={scoreFilters}
              setFilters={setScoreFilters}
            />
          )}
        </div>
      </div>
    </Grid>
  );
};
