import { useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import Collapse from '@material-ui/core/Collapse';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import TextField from '@material-ui/core/TextField';
import Popover from '@material-ui/core/Popover';

import { DashboardView } from 'src/types';
import useDashboardFilters from 'src/hooks/useDashboardFilters';
import useAuth from 'src/hooks/useAuth';
import Button from 'src/components/Button';
import { saveViewAPI } from 'src/apis';
import { ColumnItem } from './ColumnItem';

import { ReactComponent as CloseIcon } from 'src/assets/dashboard/close.svg';
import { ReactComponent as ColumnsIcon } from 'src/assets/dashboard/tableColumns.svg';

import { useDashboardStyles } from '../styles';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      width: 218,
      border: '1px solid #E1E1E3',
    },
    viewSelection: {
      width: '100%',
      border: '1px solid #C3C3C7',
      borderRadius: 2,
      padding: '0 8px',
      fontSize: 12,

      '& .MuiSvgIcon-root': {
        marginTop: -2,
      },

      '&:before, &:after': {
        border: 'none !important',
      },
    },
    saveModal: {
      width: 500,
      padding: 24,
      boxShadow: '0 16px 24px 0 rgba(0, 0, 0, 0.15)',
      background: '#FFFFFF',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    saveBtn: {
      borderRadius: 8,
      fontWeight: 400,
      fontSize: 14,
      color: '#FFFFFF',
    },
    input: {
      marginTop: 4,
      '& input': {
        padding: 8,
      },
    },
    columnsIcon: {
      border: '1px solid #C3C3C7',
      borderRadius: 8,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 36,
      height: 36,
      cursor: 'pointer',
    },
  })
);

const NormalColumns = [
  'US Elections',
  'Top IAB Categories',
  'Genres',
  'No. of stars',
];

interface ColumnsSettingProps {
  anchorEl: HTMLButtonElement | null;
  onOpen: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onClose: () => void;
}

export const ColumnsSetting = ({
  anchorEl,
  onOpen,
  onClose,
}: ColumnsSettingProps) => {
  const classes = useStyles();
  const { user } = useAuth();
  const commonClasses = useDashboardStyles();
  const [view, setView] = useState<Nullable<DashboardView>>(null);
  const [showScoreColumns, setShowScoreColumns] = useState<boolean>(false);
  const [saveTableColumns, setSaveTableColumns] = useState<boolean>(false);
  const [name, setName] = useState<string>('');

  const isViewer = user?.role === 'view';

  const {
    garmScoreColumns,
    selectedView,
    setSelectedView,
    dashboardViews,
    fetchOrganizationViews,
    selectedViewId,
    setSelectedViewId,
  } = useDashboardFilters();

  const handleChangeView = (event: any) => {
    const view = dashboardViews.filter(
      (item) => item.id === event.target.value
    )[0];
    setView(view);
    setSelectedViewId(view.id);
    setSelectedView(parseViewData(JSON.parse(view.data)));
  };

  useEffect(() => {
    if (
      garmScoreColumns?.length &&
      selectedViewId &&
      dashboardViews &&
      dashboardViews.length
    ) {
      const view = dashboardViews.filter(
        (item) => item.id === selectedViewId
      )[0];
      if (view) {
        setView(view);
        setSelectedViewId(view.id);
        setSelectedView(parseViewData(JSON.parse(view.data)));
      }
    } else {
      setView(null);
    }
  }, [selectedViewId, dashboardViews]);

  const parseViewData = (data: any[]) => {
    const scoreData: any[] =
      data.find((item) => item.type === 'score')?.data || [];
    const missedScoreColumns =
      garmScoreColumns.filter(
        (title) => !scoreData?.find((item) => item.title === title)
      ) || [];

    const missedNormalColumns =
      NormalColumns.filter(
        (title) => !data.find((item) => item.title === title)
      ) || [];
    return [
      ...data.map((item) =>
        item.type === 'score' && missedScoreColumns.length
          ? {
              type: 'score',
              title: 'GARM Scores',
              data: [
                ...scoreData,
                ...missedScoreColumns.map((item) => ({
                  title: item,
                  hide: true,
                })),
              ],
            }
          : item
      ),
      ...missedNormalColumns.map((item) => ({
        title: item,
        hide: true,
        type: 'normal',
      })),
    ];
  };

  useEffect(() => {
    if (
      garmScoreColumns?.length &&
      !selectedViewId &&
      (!selectedView || !selectedView.length)
    ) {
      const scoreColumns = garmScoreColumns.map((item) => ({
        title: item,
        hide: false,
      }));
      const tempColumns: any[] = [
        {
          type: 'score',
          title: 'GARM Scores',
          data: scoreColumns,
        },
        ...NormalColumns.map((item) => ({
          title: item,
          hide: false,
          type: 'normal',
        })),
      ];
      setSelectedView(tempColumns);
    }
  }, [selectedViewId, garmScoreColumns, selectedView, dashboardViews]);

  const handleDragEnd = (result: DropResult) => {
    const { source, destination } = result;
    if (!destination || !source) {
      return;
    }

    if (destination.index === source.index) {
      return;
    }

    const tempView = [...selectedView];
    const tempScoreViewIndex = tempView.findIndex(
      (item) => item.type === 'score'
    );
    const tempScoreView = tempView[tempScoreViewIndex].data;
    if (
      source.droppableId === 'Garm Score Columns' &&
      destination.droppableId === 'Garm Score Columns'
    ) {
      const dragItem = tempScoreView.splice(source.index, 1)[0];
      tempScoreView.splice(destination.index, 0, dragItem);
      tempView[tempScoreViewIndex] = {
        type: 'score',
        title: 'GARM Scores',
        data: tempScoreView,
      };
      setSelectedView(tempView);
      setView(null);
      setSelectedViewId(null);
    } else if (
      source.droppableId === 'Garm Columns' &&
      destination.droppableId === 'Garm Columns'
    ) {
      const dragItem = tempView.splice(source.index, 1)[0];
      tempView.splice(destination.index, 0, dragItem);
      setSelectedView(tempView);
      setView(null);
      setSelectedViewId(null);
    }
  };

  const handleChangeShow = (index: number, scoreIndex: number) => {
    const tempView = [...selectedView];
    if (scoreIndex < 0) {
      tempView[index] = {
        ...tempView[index],
        hide: !tempView[index].hide,
      };
    } else {
      const tempScoreView = tempView[index].data;
      tempScoreView[scoreIndex] = {
        ...tempScoreView[scoreIndex],
        hide: !tempScoreView[scoreIndex].hide,
      };
      tempView[index] = {
        type: 'score',
        title: 'GARM Scores',
        data: tempScoreView,
      };
    }
    setView(null);
    setSelectedViewId(null);
    setSelectedView(tempView);
  };

  const handleOpenSaveColumns = () => {
    setSaveTableColumns(true);
  };

  const handleCloseSaveColumns = () => {
    setSaveTableColumns(false);
  };

  const handleSaveColumns = async () => {
    const data = await saveViewAPI(name, JSON.stringify(selectedView));
    fetchOrganizationViews();
    setView(data);
    setSelectedViewId(data.id);
    setSaveTableColumns(false);
    setName('');
  };

  return (
    <div>
      <button
        id='columns-order'
        className={classes.columnsIcon}
        onClick={onOpen}
      >
        <ColumnsIcon />
      </button>
      <Popover
        id='columns-order'
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Grid container direction='column' className={classes.container}>
          <Grid
            item
            container
            direction='row'
            alignItems='center'
            style={{ padding: '4px 8px', borderBottom: '1px solid #E1E1E3' }}
            wrap='nowrap'
          >
            <InfoOutlined fontSize='small' style={{ marginRight: 5 }} />
            <Typography
              className={commonClasses.textXSRegular}
              style={{ color: '#686874' }}
            >
              Enable/reorder the columns on your Dashboard
            </Typography>
          </Grid>
          <Grid
            item
            container
            style={{ padding: 8, borderBottom: '1px solid #E1E1E3' }}
            wrap='nowrap'
          >
            <Select
              labelId='columns-view-select-label'
              id='columns-view-select'
              value={view?.id || ''}
              onChange={handleChangeView}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
              className={classes.viewSelection}
            >
              <MenuItem
                value=''
                disabled
                className={commonClasses.textXSRegular}
                style={{ padding: '4px 8px' }}
              >
                Select saved view...
              </MenuItem>
              {dashboardViews.map((item) => (
                <MenuItem
                  key={item.id}
                  value={item.id}
                  className={commonClasses.textXSRegular}
                  style={{ padding: '4px 8px' }}
                >
                  {item.title}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid
            item
            container
            direction='column'
            style={{ borderBottom: '1px solid #E1E1E3' }}
            wrap='nowrap'
          >
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable
                droppableId='Garm Columns'
                isDropDisabled={showScoreColumns}
              >
                {(provided) => (
                  <Grid
                    container
                    direction='column'
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {selectedView.map((item, index) => {
                      if (item.type === 'score') {
                        return (
                          <Grid container direction='column' key={index}>
                            <ColumnItem
                              title={item.title}
                              show={showScoreColumns}
                              index={index}
                              isDragDisabled={showScoreColumns}
                              onChangeShow={() =>
                                setShowScoreColumns(!showScoreColumns)
                              }
                              isCollapsable
                            />
                            <Collapse
                              in={showScoreColumns}
                              timeout='auto'
                              unmountOnExit
                            >
                              <Droppable droppableId='Garm Score Columns'>
                                {(scoreProvided) => (
                                  <Grid
                                    container
                                    direction='column'
                                    ref={scoreProvided.innerRef}
                                    {...scoreProvided.droppableProps}
                                    style={{
                                      borderBottom: '1px solid #E1E1E3',
                                    }}
                                  >
                                    {item.data.map(
                                      (score: any, scoreIndex: number) => (
                                        <ColumnItem
                                          key={scoreIndex}
                                          title={score.title}
                                          show={!score.hide}
                                          index={scoreIndex}
                                          isSubItem
                                          onChangeShow={() =>
                                            handleChangeShow(index, scoreIndex)
                                          }
                                        />
                                      )
                                    )}
                                    {scoreProvided.placeholder}
                                  </Grid>
                                )}
                              </Droppable>
                            </Collapse>
                          </Grid>
                        );
                      } else {
                        return (
                          <ColumnItem
                            key={index}
                            title={item.title}
                            show={!item.hide}
                            index={index}
                            isDragDisabled={showScoreColumns}
                            onChangeShow={() => handleChangeShow(index, -1)}
                          />
                        );
                      }
                    })}
                    {provided.placeholder}
                  </Grid>
                )}
              </Droppable>
            </DragDropContext>
          </Grid>
          {!isViewer ? (
            <Grid
              item
              container
              direction='row'
              alignItems='center'
              justifyContent='flex-end'
              style={{ padding: '4px 8px' }}
              wrap='nowrap'
            >
              <Button
                title='Save View'
                variant='outlined'
                height={24}
                onClick={handleOpenSaveColumns}
                className={commonClasses.textXSRegular}
              />
            </Grid>
          ) : (
            <></>
          )}

          <Modal
            open={saveTableColumns}
            onClose={handleCloseSaveColumns}
            aria-labelledby='table-columns-modal-title'
            aria-describedby='table-columns-modal-description'
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 800,
            }}
          >
            <Grid container direction='column' className={classes.saveModal}>
              <Grid
                item
                container
                direction='row'
                justifyContent='space-between'
                alignItems='flex-start'
                style={{ gap: 16 }}
              >
                <Grid item container direction='column' style={{ flex: 1 }}>
                  <Typography className={commonClasses.textLGBold}>
                    Save Table Columns
                  </Typography>
                  <Typography
                    className={commonClasses.textSMRegular}
                    style={{ color: '#686874' }}
                  >
                    Save your current selections for quick access by giving it a
                    name. You can use this setting on other dashboards you
                    create.
                  </Typography>
                </Grid>
                <CloseIcon cursor='pointer' onClick={handleCloseSaveColumns} />
              </Grid>
              <Grid
                item
                container
                direction='column'
                style={{ paddingTop: 24 }}
              >
                <Typography
                  className={commonClasses.textXSBold}
                  style={{ color: '#686874' }}
                >
                  Name
                </Typography>
                <TextField
                  placeholder='Enter saved view name...'
                  variant='outlined'
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className={classes.input}
                />
              </Grid>
              <Grid
                item
                container
                direction='row'
                alignItems='center'
                justifyContent='flex-end'
                style={{ paddingTop: 40, gap: 8 }}
              >
                <Button
                  title='Cancel'
                  variant='outlined'
                  height={36}
                  onClick={handleCloseSaveColumns}
                  className={commonClasses.textSMRegular}
                />
                <Button
                  title='Save View'
                  color='primary'
                  height={36}
                  onClick={handleSaveColumns}
                  className={classes.saveBtn}
                />
              </Grid>
            </Grid>
          </Modal>
        </Grid>
      </Popover>
    </div>
  );
};
