import { baseAPICreator } from './apiCreator';
import {
  BrandCustomFilter,
  BrandCustomFilterCreateBody,
} from 'src/types/filter';

const exceptionApi = baseAPICreator('/exception-filter');

export const getAllExceptionFilters = async (): Promise<
  BrandCustomFilter[]
> => {
  const { data } = await exceptionApi.get('all');
  return data;
};

export const getExceptionFilter = async (
  id: string
): Promise<BrandCustomFilter> => {
  const { data } = await exceptionApi.get(id);
  return data;
};

export const createExceptionFilter = async (
  body: BrandCustomFilterCreateBody
): Promise<BrandCustomFilter[]> => {
  const { data } = await exceptionApi.post('', body);
  return data;
};

export const updateExceptionFilter = async (
  id: string,
  body: BrandCustomFilterCreateBody
): Promise<BrandCustomFilter[]> => {
  const { data } = await exceptionApi.put(id, body);
  return data;
};

export const deleteExceptionFilter = async (id: string): Promise<void> => {
  await exceptionApi.delete(id);
};
