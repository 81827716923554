import { baseAPICreator } from './apiCreator';
import { DashboardView } from 'src/types';

const garmApi = baseAPICreator('/view');

export const getViewsAPI = async (): Promise<DashboardView[]> => {
  const { data } = await garmApi.get('');
  return data;
};

export const saveViewAPI = async (
  title: string,
  view: string
): Promise<DashboardView> => {
  const { data } = await garmApi.post('', {
    title,
    data: view,
  });
  return data;
};
