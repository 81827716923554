import { useRef, useState, useEffect } from 'react';
import { Typography } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import InfiniteScroll from 'react-infinite-scroller';

import Button from 'src/components/Button';
import Checkbox from 'src/components/Checkbox';
import { useClickAway } from 'src/hooks/useClickAway';
import { useStyles } from './AppBar.styles';

interface GenreFilterProps {
  genres: string[];
  selectedGenres: string[];
  isOpen: boolean;
  isSmallSidebar?: boolean;
  handleClose: () => void;
  handleSave: (genres: string[]) => void;
}

export const GenreFilter = ({
  genres,
  selectedGenres,
  isOpen,
  isSmallSidebar,
  handleClose,
  handleSave,
}: GenreFilterProps) => {
  const classes = useStyles();
  const [searchKey, setSearchKey] = useState<string>('');
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const ref = useRef<any>();
  const itemsPerPage = 100;
  const [hasMore, setHasMore] = useState(true);
  const [records, setrecords] = useState(itemsPerPage);

  const loadMore = () => {
    const data = genres.filter(
      (item: string) =>
        !searchKey ||
        (item && item.toLowerCase().includes(searchKey.toLowerCase()))
    );
    if (records >= data.length) {
      setHasMore(false);
    } else {
      setTimeout(() => {
        setrecords(records + itemsPerPage);
      }, 500);
    }
  };

  useClickAway({ ref, handleClickAway: handleClose });

  useEffect(() => {
    if (isOpen) {
      setSearchKey('');
      setSelectedOptions(selectedGenres);
    }
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  return (
    <div
      className={classes.selectionContainer}
      style={{ left: isSmallSidebar ? 372 : 552 }}
      ref={ref}
    >
      <div className={classes.selectionMain}>
        <div className={classes.selectionHeader}>
          <Typography
            style={{
              fontSize: 16,
              color: 'gray',
              fontWeight: 'normal',
            }}
          >
            Find podcasts by their genre
          </Typography>
          <div className={classes.searchContainer}>
            <SearchIcon />
            <input
              placeholder='Search genres...'
              value={searchKey}
              onChange={(e) => setSearchKey(e.target.value)}
            />
          </div>
          <Typography
            style={{
              fontSize: 12,
              fontWeight: 'normal',
            }}
          >
            {!searchKey
              ? `Showing ${genres.length} results`
              : `Showing ${
                  genres.filter(
                    (item: string) =>
                      item &&
                      item.toLowerCase().includes(searchKey.toLowerCase())
                  ).length
                } results for ${searchKey}`}
          </Typography>
        </div>
        <div className={classes.selectionOptions}>
          <InfiniteScroll
            pageStart={0}
            loadMore={loadMore}
            hasMore={hasMore}
            loader={<h4 className='loader'>Loading...</h4>}
            useWindow={false}
          >
            {genres
              .filter(
                (item: string) =>
                  !searchKey ||
                  (item && item.toLowerCase().includes(searchKey.toLowerCase()))
              )
              .slice(0, records)
              .map((item: any) => (
                <div key={item} className={classes.selectOption}>
                  <Checkbox
                    name={item}
                    label=''
                    checked={selectedOptions.includes(item)}
                    noPadding={true}
                    onChange={() => {
                      if (selectedOptions.includes(item)) {
                        const options = selectedOptions.filter(
                          (option) => option !== item
                        );
                        setSelectedOptions(options);
                      } else {
                        const options = [...selectedOptions, item];
                        setSelectedOptions(options);
                      }
                    }}
                  />
                  <Typography
                    style={{
                      fontSize: 14,
                      fontWeight: 'normal',
                    }}
                  >
                    {item}
                  </Typography>
                </div>
              ))}
          </InfiniteScroll>
        </div>
      </div>
      <div className={classes.selctionActions}>
        <Button
          title='Clear Selection'
          color='primary'
          variant='text'
          fontSize='sm'
          height={30}
          onClick={() => {
            setSelectedOptions([]);
          }}
        />
        <Button
          title='Select Genre'
          color='primary'
          fontSize='sm'
          height={30}
          onClick={() => {
            handleSave(selectedOptions);
          }}
        />
      </div>
    </div>
  );
};
